import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) {}

  changePassword(payload): Observable<any> {
    return this.http.put(
      `https://service.mockapi.me/api/sinanyaman/change-password`,
      payload
    );
  }

  login(payload): Observable<any> {
    return this.http.post(
      `https://service.mockapi.me/api/sinanyaman/login`,
      payload
    );
  }

  fetchUser(token): Observable<any> {
    return this.http.get(`https://service.mockapi.me/api/sinanyaman/me`);
  }

  logout(token): Observable<any> {
    return this.http.get(`https://service.mockapi.me/api/sinanyaman/logout`);
  }

  forgotPassword(payload): Observable<any> {
    return this.http.post(
      `https://service.mockapi.me/api/sinanyaman/forgot-password`,
      payload
    );
  }

  forgotPasswordOTP(payload): Observable<any> {
    return this.http.post(
      `https://service.mockapi.me/api/sinanyaman/forgot-password-otp`,
      payload
    );
  }

  createPassword(payload): Observable<any> {
    return this.http.post(
      `https://service.mockapi.me/api/sinanyaman/create-password`,
      payload
    );
  }
}

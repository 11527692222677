import {
  map,
  mergeMap,
  catchError,
  switchMap,
  tap,
  mapTo,
  finalize,
} from "rxjs/operators";

import { Injectable } from "@angular/core";
import { of, Observable } from "rxjs";

import { Actions, createEffect, ofType } from "@ngrx/effects";

import { AuthService } from "src/app/services/auth.service";
import { DialogService } from "src/app/ui/dialog/dialog.service";
import { LoadingService } from "src/app/ui/loading/loading-service.service";
import { StorageService } from "src/app/utils/storage/storage.service";

import * as fromStore from "src/app/store";

import { Store } from "@ngrx/store";
import { Router } from "@angular/router";

@Injectable()
export class AuthEffects {
  token: string = null;

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private dialogService: DialogService,
    private loadingService: LoadingService,
    private storageService: StorageService,
    private store: Store<fromStore.AppState>,
    private router: Router
  ) {
    this.store.select<any>("auth").subscribe((state) => {
      this.token = state.token;
    });
  }

  doLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromStore.AUTH_LOGIN),
      mergeMap((action: { payload }) => {
        this.loadingService.loadingPresent();
        return this.authService.login(action.payload).pipe(
          map((data) => ({
            type: fromStore.AUTH_LOGIN_SUCCESS,
            payload: data,
          })),
          tap(async (action) => {
            await this.storageService.setAuthToken(action.payload.token);
            this.router.navigate(["/account"]);
          }),
          catchError((error) => {
            this.dialogService.displayError(error.message);
            return of({
              type: fromStore.HTTP_FAIL,
              payload: { error },
            });
          }),
          finalize(() => {
            this.loadingService.loadingDismiss();
          })
        );
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromStore.AUTH_LOGOUT),
      mergeMap((action: { payload }) => {
        this.loadingService.loadingPresent();
        return this.authService.logout(action.payload).pipe(
          map((data) => ({
            type: fromStore.AUTH_LOGOUT_SUCCESS,
            payload: data,
          })),
          tap(async (action) => {
            await this.storageService.removeAuthToken();
            this.router.navigate(["/onboarding"]);
          }),
          catchError((error) =>
            of({ type: fromStore.HTTP_FAIL, payload: { error } })
          ),
          finalize(() => {
            this.loadingService.loadingDismiss();
          })
        );
      })
    )
  );

  httpFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromStore.HTTP_FAIL),
        tap((action: any) => {
          this.dialogService.displayError(action.payload.error.message);
        })
      ),
    { dispatch: false }
  );
}

import * as fromAuth from "../actions/auth.actions";

export interface AuthState {
  user: { name: string };
  isLoggedIn: boolean;
  token: string;
}

export const initialState: AuthState = {
  user: {
    name: "",
  },
  isLoggedIn: false,
  token: null,
};

export function reducer(
  state = initialState,
  action: fromAuth.AuthActions
): AuthState {
  switch (action.type) {
    case fromAuth.AUTH_LOGIN_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
        isLoggedIn: true,
      };
    }
    case fromAuth.AUTH_FETCH_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
      };
    }

    case fromAuth.AUTH_SET_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case fromAuth.AUTH_LOGOUT_SUCCESS: {
      return {
        ...state,
        user: {
          name: "",
        },
        isLoggedIn: false,
      };
    }
  }

  return state;
}

import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  NavigationExtras,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";

import * as fromStore from "src/app/store";
import { StorageService } from "src/app/utils/storage/storage.service";
import { AuthService } from "src/app/services/auth.service";
import { LoadingService } from "src/app/ui/loading/loading-service.service";
import { DialogService } from "src/app/ui/dialog/dialog.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  isLoggedIn: boolean = false;

  constructor(
    private router: Router,
    private store: Store<fromStore.AppState>,
    private storageService: StorageService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private dialogService: DialogService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(async (resolve, reject) => {
      const loggedInStatus = await this.fetchUser();
      resolve(loggedInStatus);
      if (!loggedInStatus) {
        this.router.navigate(["/onboarding"]);
      }
    });
  }

  fetchUser(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { value: token } = await this.storageService.getAuthToken();

      if (!token) {
        return resolve(false);
      }

      await this.loadingService.loadingPresent();

      this.authService
        .fetchUser(token)
        .toPromise()
        .then((data) => {
          this.store.dispatch(new fromStore.SetToken(token));
          this.store.dispatch(new fromStore.FetchUserSuccess(data));
          resolve(true);
        })
        .catch((error) => {
          this.dialogService.displayError(error.message);
          resolve(false);
        })
        .finally(() => {
          this.loadingService.loadingDismiss();
        });
    });
  }
}

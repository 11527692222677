import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { AuthGuardService } from "src/app/guards/auth-guard.service";

const routes: Routes = [
  {
    path: "",
    //redirectTo: "onboarding",
    redirectTo: "account",
    pathMatch: "full",
  },
  {
    path: "onboarding",
    loadChildren: () =>
      import("./pages/onboarding/layout/layout.module").then(
        (m) => m.LayoutPageModule
      ),
  },
  {
    path: "account",
    loadChildren: () =>
      import("./pages/account/layout/layout.module").then(
        (m) => m.LayoutPageModule
      ),
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Action } from "@ngrx/store";

export const AUTH_LOGIN = "[Auth] Login";
export const AUTH_LOGIN_SUCCESS = "[Auth] Login Success";

export const AUTH_FETCH_USER_SUCCESS = "[Auth] Fetch User Success";

export const AUTH_SET_TOKEN = "[Auth] Set Token";

export const AUTH_LOGOUT = "[Auth] Logout";
export const AUTH_LOGOUT_SUCCESS = "[Auth] Logout Success";

export class Login implements Action {
  readonly type = AUTH_LOGIN;
  constructor(public payload) {}
}

export class LoginSuccess implements Action {
  readonly type = AUTH_LOGIN_SUCCESS;
  constructor(public payload) {}
}

export class FetchUserSuccess implements Action {
  readonly type = AUTH_FETCH_USER_SUCCESS;
  constructor(public payload) {}
}

export class SetToken implements Action {
  readonly type = AUTH_SET_TOKEN;
  constructor(public payload: string) {}
}

export class Logout implements Action {
  readonly type = AUTH_LOGOUT;
  constructor(public payload) {}
}

export class LogoutSuccess implements Action {
  readonly type = AUTH_LOGOUT_SUCCESS;
  constructor(public payload) {}
}

export type AuthActions =
  | Login
  | LoginSuccess
  | FetchUserSuccess
  | SetToken
  | Logout
  | LogoutSuccess;

import { Injectable } from "@angular/core";

import { AlertController } from "@ionic/angular";
import { ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(
    public toastController: ToastController,
    public alertController: AlertController,
    public translate: TranslateService
  ) {}

  async displayConfirm(message: string) {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: this.translate.instant("CONFIRM_DIALOG_HEADER"),
        message: message,
        buttons: [
          {
            text: this.translate.instant("BUTTONS.CANCEL"),
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              reject();
            },
          },
          {
            text: this.translate.instant("BUTTONS.OK"),
            handler: () => {
              resolve();
            },
          },
        ],
      });

      alert.present();
    });
  }

  async displayError(
    message: string = this.translate.instant("UNEXPECTED_ERROR")
  ) {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: this.translate.instant("ERROR_DIALOG_HEADER"),
        message: message,
        buttons: [
          {
            text: this.translate.instant("BUTTONS.OK"),
            handler: () => {
              resolve();
            },
          },
        ],
      });

      alert.present();
    });
  }
}

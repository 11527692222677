import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  loadingStatus = false;
  guardInterval;
  constructor(public loadingController: LoadingController) {}

  async loadingPresent() {
    this.loadingStatus = true;
    return new Promise((resolve) => {
      this.loadingController
        .create({
          spinner: "circular",
        })
        .then((loading) => {
          loading.present();
          resolve();
        });
    });
  }

  async loadingDismiss() {
    this.loadingStatus = false;
    await this.loadingController.dismiss();
  }
}

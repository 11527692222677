import { Injectable } from "@angular/core";
import { Plugins } from "@capacitor/core";

const { Storage } = Plugins;

@Injectable({
  providedIn: "root",
})
export class StorageService {
  authTokenName = "HepiAuthToken";
  rememberName = "HepiPhoneRemember";

  constructor() {}

  async setAuthToken(token) {
    await Storage.set({
      key: this.authTokenName,
      value: token,
    });
  }

  async getAuthToken(): Promise<{ value: any }> {
    return await Storage.get({ key: this.authTokenName });
  }

  async removeAuthToken() {
    return await Storage.remove({ key: this.authTokenName });
  }

  async setRememberPhone(phone) {
    await Storage.set({
      key: this.rememberName,
      value: phone,
    });
  }

  async getRememberPhone(): Promise<{ value: any }> {
    return await Storage.get({ key: this.rememberName });
  }

  async removeRememberPhone() {
    return await Storage.remove({ key: this.rememberName });
  }
}
